<template>
  <!-- Start Main Loader -->
  <transition name="fadeInUp" mode="out-in" v-if="loading">
    <MainLoader />
  </transition>
  <!-- End Main Loader -->
  <div class="setting_content" v-else>
    <!--START:: SETTING CONTENT HEADER-->
    <div class="setting_content_header">
      <!--START:: ROUTE TITLE -->
      <div class="route_title"></div>
      <!--END:: ROUTE TITLE -->

      <!-- START:: EDIT ROUTE WRAPPER -->
      <div class="edit_route_wrapper">
        <router-link to="/settings/update">
          <i class="fal fa-edit"></i>
        </router-link>
      </div>
      <!-- END:: EDIT ROUTE WRAPPER -->
    </div>
    <!--END:: SETTING CONTENT HEADER-->
    <div class="row" v-if="data">
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Project Name</label>
          <p class="text">{{ data.project_name }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Email</label>
          <p class="text">{{ data.email }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Phone</label>
          <p class="text">{{ data.phone }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Description Location</label>
          <p class="text">{{ data.description_location }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">IOS Link</label>
          <p class="text">{{ data.ios_link }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Android Link</label>
          <p class="text">{{ data.android_link }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Facebook</label>
          <p class="text">{{ data.facebook }}</p>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">instagram</label>
          <p class="text">{{ data.instagram }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Pinterest</label>
          <p class="text">{{ data.pinterest }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Snapchat</label>
          <p class="text">{{ data.snapchat }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Twitter</label>
          <p class="text">{{ data.twitter }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Tik Tok</label>
          <p class="text">{{ data.tik_tok }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Whatsapp</label>
          <p class="text">{{ data.whatsapp }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Youtube</label>
          <p class="text">{{ data.youtube }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Reach Free Delivery</label>
          <p class="text">{{ data.reach_free_delivery }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Shipping Price</label>
          <p class="text">{{ data.shipping_price }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Total product for discount shipping</label>
          <p class="text">{{ data.total_product_for_discount_shipping }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Vat Percentage</label>
          <p class="text">{{ data.vat_percentage }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">{{
            $t("settings.limit_for_total_order_price")
          }}</label>
          <p class="text">{{ data.limit_for_total_order_price }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Remaining time for out of stock</label>
          <p class="text">{{ data.remaining_time_for_out_of_stock }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Available Distance </label>
          <p class="text">{{ data.available_distance }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label"
            >{{ $t("minutes_for_prevent_order") }}
          </label>
          <p class="text">{{ data.minutes_for_prevent_order }}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">{{ $t("magazine_url") }} </label>
          <p class="text">{{ data.magazine_url }}</p>
        </div>
      </div>
      <!-- <div class="col-lg-4">
        <div class="input_wrapper top_label only_read my-2">
          <label class="form-label">Distance Available For Delivery</label>
          <p class="text">{{ data.distance_available_for_delivery }}</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //ALL DATA
      data: null,
      //ALL DATA

      //LOADING
      loading: false,
      //LOADING
    };
  },
  methods: {
    //START:: GET DATA
    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "settings",
      }).then((res) => {
        this.data = res.data.data;
        this.loading = false;
      });
    },
    //END:: GET DATA
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.text {
  width: 100%;
  word-break: break-word;
  font-size: 1rem;
}
</style>
<style lang="scss">
.ltr .input_wrapper.top_label .form-label,
.ltr .input_wrapper.animated_placeholder .form-label {
  left: 0px;
  // right: auto;
}
</style>
